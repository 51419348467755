import React from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  DateField,
  ReferenceField,
  ChipField,
  required,
} from "react-admin";
import CustomPagination from "../customComponents/Pagination";
import {RolePageProvider} from "../shared/RolePageProvider";

export const DataPointList = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <ListGuesser {...props} pagination={<CustomPagination />}>
      <FieldGuesser source={"value"} />
      <ReferenceField source="client" reference="clients" label="Client">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        source="fieldConfiguration"
        reference="field_configurations"
        label="Field Configuration"
      >
        <ChipField source="displayName" />
      </ReferenceField>
      <ReferenceField source="asin" reference="asins" label="ASIN">
        <ChipField source="asin" />
      </ReferenceField>
      <FieldGuesser source={"timestamp"} />
      <FieldGuesser source={"createdAt"} label="Created" />
      <FieldGuesser source={"updatedAt"} label="Updated" />
    </ListGuesser>
  </RolePageProvider>
);

export const DataPointCreate = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <CreateGuesser {...props}>
      <InputGuesser source={"value"} validate={[required()]} />
      <ReferenceInput
        label="Client"
        source="client"
        reference="clients"
        validate={[required()]}
        filterToQuery={searchText => ({ name: searchText })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source={"timestamp"} validate={[required()]} />
    </CreateGuesser>
  </RolePageProvider>
);

export const DataPointEdit = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <EditGuesser {...props}>
      <InputGuesser source={"value"} validate={[required()]} />
      <ReferenceInput
        label="Client"
        source="client"
        reference="clients"
        validate={[required()]}
        filterToQuery={searchText => ({ name: searchText })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source={"timestamp"} validate={[required()]} />
      <DateField source={"createdAt"} addLabel={true} showTime={true} />
      <DateField source={"updatedAt"} addLabel={true} showTime={true} />
    </EditGuesser>
  </RolePageProvider>
);

export const DataPointShow = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <ShowGuesser {...props}>
      <FieldGuesser source={"value"} />
      <ReferenceField source="client" reference="clients" label="Client">
        <ChipField source="name" />
      </ReferenceField>
      <DateField source={"timestamp"} addLabel={true} showTime={true} />
      <DateField source={"createdAt"} addLabel={true} showTime={true} />
      <DateField source={"updatedAt"} addLabel={true} showTime={true} />
    </ShowGuesser>
  </RolePageProvider>
);
