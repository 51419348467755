import React, { Fragment, cloneElement } from "react";
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import {
  ChipField,
  Filter,
  FormDataConsumer,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  AutocompleteInput,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  downloadCSV,
} from "react-admin";
import { useForm } from "react-final-form";
import jsonExport from "jsonexport/dist";
import CustomPagination from "../customComponents/Pagination";
import {formatDate} from "../../utils";

const AsinCategories = ({ formData, ...props }) => {
  const form = useForm();
  const categoryFilter = {
    pagination: false,
  };
  const subcategoryFilter = {
    pagination: false,
  };

  if (formData.client) {
    categoryFilter.client = {
      id: formData.client || "",
    };
  }

  if (formData.category) {
    subcategoryFilter.category = {
      id: formData.category || "",
    };
  }

  return (
    <Fragment>
      <ReferenceInput
        allowEmpty={true}
        label="Client"
        source="client"
        reference="clients"
        validate={[required()]}
        onChange={() => {
          form.change("category", null);
          form.change("subcategory", null);
        }}
        {...props}
        filterToQuery={(searchText) => ({ name: searchText })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        allowEmpty={true}
        label="Category"
        source="category"
        reference="categories"
        validate={[required()]}
        disabled={!formData.client}
        onChange={() => form.change("subcategory", null)}
        filter={categoryFilter}
        {...props}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <br key={"jump"} />
      <ReferenceInput
        allowEmpty={true}
        label="Subcategory"
        source="subcategory"
        reference="subcategories"
        disabled={!formData.category}
        filter={subcategoryFilter}
        {...props}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Fragment>
  );
};

const AsinDetailTitle = ({ record }) => (
  <span>
    ASIN: {record ? `${record.asin} - ${record.productName || "[N/A]"}` : ""}
  </span>
);

export const AsinCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"asin"} validate={[required()]} />
    <InputGuesser source={"productName"} label={"Product Name"} />
    <FormDataConsumer>
      {(formDataProps) => <AsinCategories {...formDataProps} />}
    </FormDataConsumer>
  </CreateGuesser>
);

export const AsinEdit = (props) => (
  <EditGuesser {...props} title={<AsinDetailTitle />}>
    <InputGuesser source={"asin"} validate={[required()]} />
    <InputGuesser source={"productName"} label={"Product Name"} />
    <FormDataConsumer>
      {(formDataProps) => <AsinCategories {...formDataProps} />}
    </FormDataConsumer>
  </EditGuesser>
);

export const AsinShow = (props) => (
  <ShowGuesser {...props} title={<AsinDetailTitle />}>
    <FieldGuesser source={"asin"} addLabel={true} />
    <FieldGuesser source={"productName"} addLabel={true} />
    <ReferenceField source="client" reference="clients" label={"Client"}>
      <ChipField source="name" />
    </ReferenceField>
    <ReferenceField source="category" reference="categories" label={"Category"}>
      <ChipField source="name" />
    </ReferenceField>
    <ReferenceField
      source="subcategory"
      reference="subcategories"
      label={"Subcategory"}
    >
      <ChipField source="name" />
    </ReferenceField>
  </ShowGuesser>
);

const AsinFilters = (props) => {
  const categoryFilter = {
    pagination: false,
  };
  const subCategoryFilter = {
    pagination: false,
  };

  if (props.filterValues.client) {
    categoryFilter.client = {
      id: props.filterValues.client || "",
    };
  }

  if (props.filterValues.category) {
    subCategoryFilter.category = {
      id: props.filterValues.category || "",
    };
  }

  return (
    <Filter {...props}>
      <TextInput source="asin" label="ASIN" />
      <ReferenceInput
        source="client"
        reference="clients"
        label="Client"
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="category"
        reference="categories"
        filter={categoryFilter}
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="productName" label="Product Name" />
      <ReferenceInput
        source="subcategory"
        reference="subcategories"
        filter={subCategoryFilter}
        filterToQuery={(searchText) => ({
          name: searchText,
        })}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const AsinList = (props) => {
  const ListActions = (props) => {
    const { className, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();
    const exporter = (asins, fetchRelatedRecords) => {
      fetchRelatedRecords(
        asins,
        "client",
        "clients",
        "subcategories",
        "categories"
      ).then((clients) => {
        const data = asins.map((asin) => {
          return {
            ASIN: asin.asin,
            "Product Name": asin.productName,
            "Category Name": asin.categoryName,
            "Subcategory Name": asin.subcategoryName,
            Client: clients[asin.client].name,
          };
        });
        jsonExport(
          data,
          {
            headers: [
              "ASIN",
              "Product Name",
              "Category Name",
              "Subcategory Name",
              "Client",
            ],
          },
          (err, csv) => {
            downloadCSV(csv, `scout-asins-${formatDate(new Date())}`);
          }
        );
      });
    };
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={total}
          exporter={exporter}
        />
      </TopToolbar>
    );
  };
  return (
    <ListGuesser
      {...props}
      filters={<AsinFilters />}
      pagination={<CustomPagination />}
      actions={<ListActions />}
    >
      <FieldGuesser source={"asin"} />
      <FieldGuesser source={"productName"} label="Product Name" />
      <ReferenceField
        source="client"
        reference="clients"
        label={"Client"}
        sortBy="client.name"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        source="category"
        reference="categories"
        label={"Category"}
        sortBy="category.name"
      >
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        source="subcategory"
        reference="subcategories"
        label={"Subcategory"}
        sortBy="subcategory.name"
      >
        <ChipField source="name" />
      </ReferenceField>
    </ListGuesser>
  );
};
