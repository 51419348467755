import React from 'react';
export const RoleLinkProvider = ({ allowed, children }) => {
  const role = window.localStorage.getItem("role");
  if (
    role === null ||
    allowed.find(_allowedRole => _allowedRole === role) === undefined
  ) {
    return <></>;
  } else {
    return <>{children}</>;
  }
};
