import React, { cloneElement } from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  ReferenceInput,
  CreateButton,
  ExportButton,
  ReferenceField,
  TopToolbar,
  ChipField,
  sanitizeListRestProps,
  required,
  TextInput,
  Filter,
  AutocompleteInput,
  downloadCSV,
} from "react-admin";
import CustomPagination from "../customComponents/Pagination";
import jsonExport from "jsonexport/dist";
import {formatDate} from "../../utils";
const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const exporter = (categories) => {
    const postsForExport = categories.map((category) => {
      return {
        "Category Name": category.name,
        Client: category.clientName,
      };
    });
    jsonExport(
      postsForExport,
      {
        headers: ["Category Name", "Client"],
      },
      (err, csv) => {
        downloadCSV(csv, `scout-categories-${formatDate(new Date())}`);
      }
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={total}
      />
    </TopToolbar>
  );
};

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export const CategoryList = (props) => {
  return (
    <ListGuesser
      {...props}
      actions={<ListActions />}
      filters={
        <Filter>
          <TextInput source="name" label="Name" />
          <ReferenceInput
            source="client"
            reference="clients"
            label="Client"
            filterToQuery={(searchText) => ({
              name: searchText,
            })}
            filter={{ pagination: false }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Filter>
      }
      pagination={<CustomPagination />}
    >
      <FieldGuesser source={"name"} />
      <ReferenceField
        source="client"
        reference="clients"
        label={"Client"}
        sortBy="client.name"
      >
        <ChipField source="name" />
      </ReferenceField>
      <FieldGuesser source={"uncategorized"} />
    </ListGuesser>
  );
};

const CategoryDetailTitle = ({ record }) => (
  <span>Category: {record ? `${record.clientName} - ${record.name}` : ""}</span>
);

export const CategoryCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]} />
    <ReferenceInput
      label="Client"
      source="client"
      reference="clients"
      validate={[required()]}
      filterToQuery={(searchText) => ({ name: searchText })}
      filter={{ pagination: false }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
);

export const CategoryEdit = (props) => (
  <EditGuesser {...props} title={<CategoryDetailTitle />}>
    <InputGuesser source={"name"} validate={[required()]} />
    <ReferenceInput
      label="Client"
      source="client"
      reference="clients"
      validate={[required()]}
      filterToQuery={(searchText) => ({ name: searchText })}
      filter={{ pagination: false }}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </EditGuesser>
);

export const CategoryShow = (props) => (
  <ShowGuesser {...props} title={<CategoryDetailTitle />}>
    <FieldGuesser source={"name"} addLabel={true} />
    <ReferenceField source="client" reference="clients" label={"Client"}>
      <ChipField source="name" />
    </ReferenceField>
    <FieldGuesser source={"uncategorized"} addLabel={true} />
  </ShowGuesser>
);
