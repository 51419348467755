import React, {cloneElement} from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";

import { PasswordInput, SelectInput, required, useListContext,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar, } from "react-admin";
import CustomPagination from "../customComponents/Pagination";
import {RolePageProvider} from "../shared/RolePageProvider";

export const ImporterList = (props) => {
  const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
    } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={total}
        />
      </TopToolbar>
    );
  };
  return (
    <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
      <ListGuesser {...props} pagination={<CustomPagination />} actions={<ListActions />}>
        <FieldGuesser source={"name"} sortable={false}/>
        <FieldGuesser source={"type"} sortable={false}/>
        <FieldGuesser source={"port"} sortable={false}/>
        <FieldGuesser source={"databaseName"} label="Database Name" sortable={false}/>
        <FieldGuesser source={"username"} sortable={false}/>
      </ListGuesser>
    </RolePageProvider>
  );
}

export const ImporterCreate = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <CreateGuesser {...props}>
      <InputGuesser source={"name"} validate={[required()]} />
      <SelectInput
        source="type"
        validate={[required()]}
        choices={[
          { id: "vendor-central", name: "Vendor Central (Bridgetown)" },
          { id: "vendor-central-big-query", name: "Vendor Central (BigQuery)" },
          { id: "seller-central", name: "Seller Central (Bridgetown)" },
          { id: "seller-central-big-query", name: "Seller Central (BigQuery)" },
          { id: "seller-central-big-query-transactional", name: "Seller Central (BigQuery) Transactional" },
          { id: "data-virtuality", name: "Seller Central (Data Virtuality)" },
        ]}
      />
      <InputGuesser source={"host"} validate={[required()]} />
      <InputGuesser source={"port"} validate={[required()]} />
      <InputGuesser source={"databaseName"} validate={[required()]} />
      <InputGuesser source={"username"} validate={[required()]} />
      <PasswordInput source={"password"} validate={[required()]} />
    </CreateGuesser>
  </RolePageProvider>
);

const ImporterDetailTitle = ({ record }) => (
  <span>Importer: {record ? record.name : ''}</span>
);

export const ImporterEdit = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <EditGuesser {...props} title={<ImporterDetailTitle />}>
      <InputGuesser source={"name"} validate={[required()]} />
      <SelectInput
        source="type"
        validate={[required()]}
        choices={[
          { id: "vendor-central", name: "Vendor Central (Bridgetown)" },
          { id: "vendor-central-big-query", name: "Vendor Central (BigQuery)" },
          { id: "seller-central", name: "Seller Central (Bridgetown)" },
          { id: "seller-central-big-query", name: "Seller Central (BigQuery)" },
          { id: "seller-central-big-query-transactional", name: "Seller Central (BigQuery) Transactional" },
          { id: "data-virtuality", name: "Seller Central (Data Virtuality)" },
        ]}
      />
      <InputGuesser source={"host"} validate={[required()]} />
      <InputGuesser source={"port"} validate={[required()]} />
      <InputGuesser source={"databaseName"} validate={[required()]} />
      <InputGuesser source={"username"} validate={[required()]} />
      <PasswordInput source={"password"} />
    </EditGuesser>
  </RolePageProvider>
);

export const ImporterShow = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <ShowGuesser {...props} title={<ImporterDetailTitle />}>
      <FieldGuesser source={"name"} addLabel={true} />
      <FieldGuesser source={"type"} addLabel={true} />
      <FieldGuesser source={"port"} addLabel={true} />
      <FieldGuesser source={"databaseName"} />
      <FieldGuesser source={"username"} addLabel={true} />
    </ShowGuesser>
  </RolePageProvider>
);
