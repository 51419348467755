import { HttpError } from 'react-admin';
import {
  hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";
import {
  fetchJsonLd,
  getDocumentationUrlFromHeaders,
} from '@api-platform/api-doc-parser';
import { promises } from 'jsonld';
import React from "react";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import { Redirect, Route } from "react-router-dom";


let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  entrypoint = window._env_.API_URL;
}

const fetchHeaders = {
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const fetchHydra = (url, options = {}) => {
  return fetchJsonLd(url, {
    ...options,
    headers: new Headers(fetchHeaders),
  }).then((data) => {
    const status = data.response.status;

    if (status < 200 || status >= 300) {
      const body = data.body;
      delete body.trace;

      return promises
        .expand(body, {
          base: getDocumentationUrlFromHeaders(data.response.headers),
        })
        .then((json) => {
          return Promise.reject(
            new HttpError(
              json[0]['http://www.w3.org/ns/hydra/core#description'][0][
                '@value'
                ],
              status,
              json,
            ),
          );
        })
        .catch((e) => {
          if (e.hasOwnProperty('body')) {
            return Promise.reject(e);
          }

          return Promise.reject(
            new HttpError(data.body['hydra:description'], status),
          );
        });
    }

    return {
      status: status,
      headers: data.response.headers,
      json: data.body,
    };
  });
}
const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, {
    headers: new Headers(fetchHeaders),
  }).then(
    ({ api }) => ({ api }),
    (result) => {
      window.localStorage.removeItem("token");
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route
                path="/"
                render={() => {
                  return window.localStorage.getItem("token") ? (
                    window.location.reload()
                  ) : (
                    <Redirect to="/login" />
                  );
                }}
              />,
            ],
          });

        default:
          return Promise.reject(result);
      }
    }
  );

export const dataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser
);
