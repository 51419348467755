import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#0d2d3d" },
    secondary: { main: "#0d2d3d" },
  },
  overrides: {
    MuiTableCell: {
      head: {
        whiteSpace: "nowrap",
        padding: "15px !important",
      },
    },
  },
});

export default theme;
