import React from "react";
export const Rule = ({ height, width, fill, onClick }) => (
  <div
    style={{
      height: height || "24px",
      width: width || "24px",
      cursor: onClick ? "pointer" : "auto",
    }}
    role="button"
  >
    <svg
      height={height || "24px"}
      width={height || "24px"}
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
    >
      <g>
        <rect fill="none" height={height || "24px"} width={height || "24px"} />
        <path
          fill={fill || "#737373"}
          d="M16.54,11L13,7.46l1.41-1.41l2.12,2.12l4.24-4.24l1.41,1.41L16.54,11z M11,7H2v2h9V7z M21,13.41L19.59,12L17,14.59 L14.41,12L13,13.41L15.59,16L13,18.59L14.41,20L17,17.41L19.59,20L21,18.59L18.41,16L21,13.41z M11,15H2v2h9V15z"
        />
      </g>
    </svg>
  </div>
);
export const Upload = ({ height, width, fill, onClick }) => (
  <div
    style={{
      height: height || "24px",
      width: width || "24px",
      cursor: onClick ? "pointer" : "auto",
    }}
    role="button"
  >
    <svg
      height={height || "24px"}
      width={height || "24px"}
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
    >
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path
          fill={fill || "#737373"}
          d="M5,20h14v-2H5V20z M5,10h4v6h6v-6h4l-7-7L5,10z"
        />
      </g>
    </svg>
  </div>
);
