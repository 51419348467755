import React, { useEffect } from "react";
import { AppBar, Layout, MenuItemLink } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Rule, Upload } from "./svg/SVGS";
import {
  BarChart,
  BubbleChart,
  Business,
  Category,
  Help,
  ImportContacts,
  ImportExport,
  List,
  Person,
} from "@material-ui/icons";

import Wordmark from "./Wordmark";
import {RoleLinkProvider} from "./components/shared/RoleLinkProvider";


const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textTransform: "capitalize",
  },
  spacer: {
    flex: 1,
  },
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar style={{ background: 'transparent linear-gradient( 5deg, #007498 0%, #004a5f 100% ) 0% 0% no-repeat padding-box' }} {...props}>
      <Typography
        variant="h6"
        className={classes.title}
        id="react-admin-title"
      />

      <Wordmark />
      <span className={classes.spacer} />
    </AppBar>
  );
};

const SideBar = () => {

  return (
    <>
      <MenuItemLink
        leftIcon={<Business />}
        key="clients"
        to="/clients"
        primaryText="Clients"
        sidebarIsOpen={true}
      />
      <MenuItemLink
        leftIcon={<Person />}
        key="users"
        to="/users"
        primaryText="Users"
        sidebarIsOpen={true}
      />
      <RoleLinkProvider allowed={['ROLE_DATA_MANAGER']}>
        <MenuItemLink
          leftIcon={<ImportContacts />}
          key="clientImporters"
          to="/client_importers"
          primaryText="Client Importers"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider allowed={['ROLE_DATA_MANAGER']}>
        <MenuItemLink
          leftIcon={<ImportExport />}
          key="importers"
          to="/importers"
          primaryText="Importers"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider allowed={['ROLE_DATA_MANAGER']}>
        <MenuItemLink
          leftIcon={<Rule />}
          key="fieldConfigurations"
          to="/field_configurations"
          primaryText="Field Configurations"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <RoleLinkProvider allowed={['ROLE_DATA_MANAGER']}>
        <MenuItemLink
          leftIcon={<BarChart />}
          key="dataPoints"
          to="/data_points"
          primaryText="Data Points"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
      <MenuItemLink
        leftIcon={<Category />}
        key="categories"
        to="/categories"
        primaryText="Categories"
        sidebarIsOpen={true}
      />
      <MenuItemLink
        leftIcon={<BubbleChart />}
        key="subcategories"
        to="/subcategories"
        primaryText="Subcategories"
        sidebarIsOpen={true}
      />
      <MenuItemLink
        leftIcon={<List />}
        key="asins"
        to="/asins"
        primaryText="ASINs"
        sidebarIsOpen={true}
      />
      <MenuItemLink
        leftIcon={<Help />}
        key="help"
        to="/help"
        primaryText="Help"
        sidebarIsOpen={true}
      />
      <RoleLinkProvider allowed={['ROLE_DATA_MANAGER']}>
        <MenuItemLink
          leftIcon={<Upload />}
          key="bulkUpload"
          to="/bulk_upload"
          primaryText="Bulk Upload"
          sidebarIsOpen={true}
        />
      </RoleLinkProvider>
    </>
  );
};

const CustomLayout = (props) => {
  const setTitle = async () => {
    await setTimeout(() => {
      const title = document.getElementById("react-admin-title")?.textContent;
      document.title = title ? `${title} - Scout Dashboard` : "Scout Dashboard";
    }, 200);
  };

  useEffect(() => {
    setTitle();
  }, [props]);
  return <Layout {...props} appBar={CustomAppBar} menu={SideBar} />;
};

export default CustomLayout;
