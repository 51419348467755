import React, { cloneElement, Fragment, useState } from "react";
import {
  CreateGuesser,
  EditGuesser,
  FieldGuesser,
  InputGuesser,
  ListGuesser,
  ShowGuesser,
} from "@api-platform/admin";
import {
  BooleanInput,
  Button,
  ChipField,
  EditButton,
  Filter,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  SelectInput,
  SingleFieldList,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
  FormDataConsumer,
  FunctionField,
  required,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
  email,
  SelectArrayInput,
} from "react-admin";
import { useForm } from "react-final-form";
import jwt_decode from "jwt-decode";
import SendIcon from "@material-ui/icons/Send";
import { extractUUID } from "../../utils";
import CustomPagination from "../customComponents/Pagination";

const rolesMap = [
  { id: "ROLE_SUPER_ADMIN", name: "Super Administrator" },
  { id: "ROLE_ADMIN", name: "Administrator" },
  { id: "ROLE_DATA_MANAGER", name: "Data Manager" },
  { id: "ROLE_ACCOUNT_MANAGER", name: "Account Manager" },
  { id: "ROLE_CLIENT_USER", name: "Client User" },
];

const ClientsAndUserRole = ({ formData, ...props }) => {
  const form = useForm();

  const onRoleChange = (value) => {
    switch (value.target.value) {
      case "ROLE_ADMIN":
      case "ROLE_DATA_MANAGER":
      case "ROLE_ACCOUNT_MANAGER":
        form.change("client", null);
        break;
      case "ROLE_CLIENT_USER":
        form.change("managedClients", null);
        break;
      default:
        form.change("client", null);
        form.change("managedClients", null);
    }
  };

  const canAdminClients = () =>
    formData.role === "ROLE_ADMIN" ||
    formData.role === "ROLE_ACCOUNT_MANAGER" ||
    formData.role === "ROLE_DATA_MANAGER";
  const isClientUser = () => formData.role === "ROLE_CLIENT_USER";

  return (
    <Fragment>
      <SelectInput
        source="role"
        label="User Role"
        onChange={onRoleChange}
        validate={[required()]}
        choices={[
          { id: "ROLE_ADMIN", name: "Administrator" },
          { id: "ROLE_ACCOUNT_MANAGER", name: "Account Manager" },
          { id: "ROLE_DATA_MANAGER", name: "Data Manager" },
          { id: "ROLE_CLIENT_USER", name: "Client User" },
        ]}
        {...props}
      />
      <br/>
      <ReferenceArrayInput
        label="Managed Clients"
        source="managedClients"
        reference="clients"
        disabled={!canAdminClients()}
        {...props}
        filterToQuery={(searchText) => ({ name: searchText })}
        filter={{ pagination: false, enabled: true }}
      >
        <SelectArrayInput optionText="name" filter={{ enabled: true }}/>
      </ReferenceArrayInput>
      <br/>
      <ReferenceArrayInput
        label="Clients"
        source="clients"
        reference="clients"
        disabled={!isClientUser()}
        {...props}
        filterToQuery={(searchText) => ({ name: searchText })}
        filter={{ pagination: false, enabled: true }}
      >
        <SelectArrayInput optionText="name" filter={{ enabled: true }}/>
      </ReferenceArrayInput>
    </Fragment>
  );
};

const InviteUserButton = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const sendInvite = () => {
    dataProvider
      .create("invite_users", {
        data: {
          userId: extractUUID(record.id),
        },
      })
      .then((response) => {
        if (response?.data?.requestId) {
          notify("User invitation sent successfully");
        } else {
          notify("There was an error with the request.");
        }
      })
      .catch((ex) => notify(ex.message));
  };

  return (
    <Button onClick={sendInvite} label="Send Invite">
      <SendIcon />
    </Button>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  showOnlyEnabled,
  onToggleEnabled,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={total}
      />
      <Button
        onClick={() => onToggleEnabled()}
        label={showOnlyEnabled ? "Show Disabled" : "Show Enabled"}
      />
    </TopToolbar>
  );
};

export const UserList = (props) => {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);

  return (
    <ListGuesser
      {...props}
      filter={{ enabled: showOnlyEnabled }}
      actions={
        <ListActions
          showOnlyEnabled={showOnlyEnabled}
          onToggleEnabled={() => setShowOnlyEnabled(!showOnlyEnabled)}
        />
      }
      filters={
        <Filter>
          <TextInput source="email" label="Email" />
          <ReferenceInput
            source="client"
            reference="clients"
            label="Client"
            filterToQuery={(searchText) => ({
              name: searchText,
            })}
            filter={{ pagination: false }}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </Filter>
      }
      pagination={<CustomPagination />}
    >
      <FieldGuesser source="email" />
      <FieldGuesser source="firstName" addLabel label="First Name" />
      <FieldGuesser source="lastName" addLabel label="Last Name" />
      <ReferenceArrayField
        source="clients"
        reference="clients"
        label="Clients"
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <FunctionField
        label="Role"
        sortBy="role"
        render={(record) => {
          const foundRole = rolesMap.find((role) => role.id === record.role);
          return foundRole ? `${foundRole.name}` : `${record.role}`;
        }}
      />
      <FieldGuesser source="enabled" />
      <FieldGuesser source="lastLoginDate" label="Last Login Date" />
      <FieldGuesser source="loginCount" label="Login Count" />
      <InviteUserButton />
    </ListGuesser>
  );
};

export const UserCreate = (props) => {
  const validateEmail = email();
  return (
    <CreateGuesser {...props}>
      <InputGuesser
        source="email"
        validate={[required(), validateEmail]}
        type="email"
      />
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <FormDataConsumer>
        {(formDataProps) => <ClientsAndUserRole {...formDataProps} />}
      </FormDataConsumer>
      <TextInput source="newPassword" type="password" label="New Password" />
      <BooleanInput source="enabled" defaultValue={true} />
    </CreateGuesser>
  );
};

const UserDetailTitle = ({ record }) => (
  <span>User: {record ? record.email : ""}</span>
);

export const UserEdit = (props) => {
  const validateEmail = email();
  const allowPwd =
    jwt_decode(window.localStorage.getItem("token")).roles[0] ===
    "ROLE_SUPER_ADMIN";
  return (
    <EditGuesser {...props} title={<UserDetailTitle />}>
      <InputGuesser
        source="email"
        validate={[required()]}
        type="email"
        validate={validateEmail}
      />
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <FormDataConsumer>
        {(formDataProps) => <ClientsAndUserRole {...formDataProps} />}
      </FormDataConsumer>
      {allowPwd && (
        <TextInput source="newPassword" type="password" label="New Password" />
      )}
      <BooleanInput source="enabled" />
    </EditGuesser>
  );
};

const UserShowActions = ({ basePath, data }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const sendUserPasswordResetEmail = () => {
    dataProvider
      .create("reset_passwords", {
        data: {
          userId: extractUUID(data.id),
        },
      })
      .then((response) => {
        if (response?.data?.requestId) {
          notify("Password Reset Sent Successfully");
        } else {
          notify("There was an error with the request.");
        }
      })
      .catch((ex) => notify(ex.message));
  };
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <Button
        onClick={sendUserPasswordResetEmail}
        label="Send Password Reset"
      />
    </TopToolbar>
  );
};

export const UserShow = (props) => (
  <ShowGuesser
    {...props}
    actions={<UserShowActions />}
    title={<UserDetailTitle />}
  >
    <FieldGuesser source={"email"} addLabel={true} />
    <FieldGuesser source="firstName" addLabel label={"First Name"} />
    <FieldGuesser source="lastName" addLabel label={"Last Name"} />
    <ReferenceArrayField
      source="managedClients"
      reference="clients"
      label="Managed Clients"
      filter={{ enabled: true }}
    >
      <SingleFieldList>
        <ChipField source={"name"} />
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField
      source="clients"
      reference="clients"
      label="Clients"
      filter={{ enabled: true }}
    >
      <SingleFieldList>
        <ChipField source={"name"} />
      </SingleFieldList>
    </ReferenceArrayField>
    <FunctionField
      label="Role"
      render={(record) => {
        const foundRole = rolesMap.find((role) => role.id === record.role);
        return foundRole ? `${foundRole.name}` : `${record.role}`;
      }}
    />
    <FieldGuesser source={"enabled"} addLabel={true} />
  </ShowGuesser>
);
