import React from 'react';
import { themeColors } from "./styles";

const Wordmark = ({ width = 106 }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 112 32"
    width={`${width}px`}
  >
    <g id="Layer_1-2" data-name="Layer 1-2">
      <g>
        <path
          style={{fill: themeColors.goldenrod}}
          d="M25.27,25.07c0,2.92-2.04,4.4-3.71,4.4-1.56,0-1.91-.67-2.49-.66-.53.02-.98.55-1.46.55-.34,0-.45-.19-.4-.51.08-.45.13-.98.13-1.61l-.02-6.65c0-1.11-.76-.99-.76-1.47,0-.21.11-.32.5-.5.67-.32,1.83-.71,2.09-.71s.37.14.37.48l-.02,2.2v2.02c.51-.67,1.31-1.15,2.41-1.17,1.84-.02,3.35,1.4,3.35,3.63h0ZM23.2,26.05c0-1.94-.88-3.34-2.06-3.34-1.08,0-1.68,1.08-1.67,2.06l.02,1.17c0,1.44.92,2.54,2.04,2.54s1.67-1.14,1.67-2.43Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M32.8,22.83c0,.64-.34,1.06-.78,1.08-.78,0-.76-.83-1.52-.83-.58,0-1.11.67-1.11,1.84v2.17c0,1.49.77,1.12.77,1.83,0,.32-.26.4-.71.4h-2.29c-.45,0-.74-.1-.74-.42,0-.69.8-.32.8-1.81v-3.05c0-.45-.18-.74-.5-.98-.16-.11-.3-.23-.3-.45,0-.26.13-.37.51-.55.59-.29,1.59-.58,1.84-.59.27,0,.39.16.39.48v.95c.59-.98,1.44-1.44,2.22-1.44.92,0,1.43.59,1.43,1.38h0Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M41.98,28.35c0,.55-.89,1.09-1.65,1.09-.71,0-1.12-.39-1.36-1.19-.76.83-1.84,1.2-2.81,1.2-1.78,0-2.94-1.03-2.94-2.36,0-1.54,1.35-2.65,3.63-2.65.42,0,.74.05,1.28.13-.29-1.01-.61-1.81-1.81-1.81-.73,0-1.38.3-1.96.62-.3.19-.64-.05-.4-.45.4-.67,1.62-1.49,3.16-1.49,2.06,0,2.66,1.41,3.02,2.75l.78,3.02c.18.66.4.89.76.87.18,0,.3.08.3.27h0ZM38.69,27.05c-.05-.27-.19-.67-.34-1.24-.11-.37-.74-.53-1.25-.53-1.14,0-1.81.58-1.81,1.46.02.92.69,1.59,1.74,1.59.9,0,1.8-.62,1.67-1.28Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M51.58,27.49c.21.98.85.85.85,1.41,0,.32-.27.4-.71.4h-2.25c-.45,0-.72-.1-.72-.42,0-.53.83-.48.62-1.53l-.5-2.54c-.19-1.01-.5-2.01-1.56-1.99-.85,0-1.62.77-1.62,2.18v2.06c0,1.49.77,1.12.77,1.83,0,.32-.26.4-.71.4h-2.29c-.45,0-.74-.1-.74-.42,0-.69.8-.32.8-1.81v-3.05c0-.45-.18-.74-.5-.98-.16-.11-.3-.23-.3-.45,0-.26.13-.37.51-.55.59-.29,1.59-.58,1.84-.59.27,0,.39.16.39.48v.96c.61-.96,1.59-1.46,2.62-1.46,1.83,0,2.52,1.19,2.94,3.31l.55,2.75h0Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M60.81,20.59l-.03,6.29c0,1.19.95.93.95,1.4,0,.23-.16.39-.53.59-.46.24-1.38.56-1.83.56s-.62-.27-.67-.95l-.02-.32c-.51.74-1.35,1.33-2.6,1.28-1.88-.03-3.21-1.49-3.21-3.56,0-2.78,2.18-4.45,4.06-4.45.66,0,1.25.16,1.7.42v-1.25c0-1.11-.76-1.01-.76-1.49,0-.21.11-.32.5-.5.67-.32,1.83-.71,2.09-.71s.37.14.37.48l-.02,2.2ZM58.63,26.29v-.96c-.03-.66-.13-1.31-.35-1.77-.34-.74-.9-1.11-1.61-1.11-1.09,0-1.74.96-1.74,2.46,0,1.97.98,3.16,2.09,3.16.96,0,1.51-.8,1.61-1.78Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M74.48,21.59c.45,0,.72.1.72.42,0,.59-.64.37-1.2,1.67l-2.22,5.15c-.1.21-.21.39-.82.5-.56.1-.77.03-.87-.24l-1.72-4.63-1.63,4.36c-.08.21-.32.39-.77.5-.61.14-.82.03-.93-.24l-2.17-5.39c-.51-1.31-1.17-1.09-1.17-1.7,0-.32.27-.4.71-.4h2.46c.45,0,.74.1.74.42,0,.59-.93.37-.4,1.68l1.27,3.14,1.8-4.79c.08-.21.18-.37.59-.46.55-.13.74-.02.83.26l1.91,5.01,1.37-3.18c.55-1.3-.32-1.08-.32-1.69,0-.32.26-.4.69-.4h1.14v.02Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M75.37,25.76c0-2.41,1.88-4.32,4.43-4.32,2.22,0,3.74,1.63,3.74,3.78,0,2.43-1.84,4.26-4.4,4.26-2.13,0-3.78-1.43-3.78-3.71h0ZM81.4,26.34c0-1.86-.95-3.83-2.33-3.83-1.15,0-1.59,1.09-1.59,2.23,0,1.88.96,3.67,2.34,3.67,1.09,0,1.58-.96,1.58-2.07Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M91.54,21.61c.46,0,.74.1.74.4,0,.61-.67.39-1.17,1.7l-1.84,4.9c-.14.39-.24.55-1.05.73-.87.21-1.09.07-1.24-.34l-2.09-5.3c-.51-1.3-1.17-1.08-1.17-1.68,0-.32.27-.4.71-.4h2.44c.45,0,.73.1.73.4,0,.61-.92.39-.4,1.7l1.47,3.8,1.43-3.8c.48-1.31-.37-1.09-.37-1.7,0-.32.26-.4.71-.4h1.11Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M100.02,27.25c-.32.9-1.65,2.22-3.79,2.22-2.28,0-3.82-1.58-3.82-3.78,0-2.47,1.91-4.26,4.16-4.26,1.93,0,3.03,1.15,3.26,2.54.05.26-.07.48-.37.62-1.63.74-2.94,1.24-4.61,1.84.34.74,1.08,1.46,2.31,1.46.98,0,1.77-.5,2.34-1.06.32-.32.67-.07.51.4h0ZM94.44,24.53c0,.35.05.74.13,1.01.93-.32,1.84-.64,2.71-1.01.27-.13.48-.24.37-.66-.23-.93-1.01-1.51-1.78-1.37-.93.11-1.43.99-1.43,2.02h0Z"
        />
        <path
          style={{fill: themeColors.goldenrod}}
          d="M109.91,27.49c.21.98.85.85.85,1.41,0,.32-.27.4-.71.4h-2.25c-.45,0-.72-.1-.72-.42,0-.53.83-.48.62-1.53l-.5-2.54c-.19-1.01-.5-2.01-1.56-1.99-.85,0-1.62.77-1.62,2.18v2.06c0,1.49.77,1.12.77,1.83,0,.32-.26.4-.71.4h-2.29c-.45,0-.74-.1-.74-.42,0-.69.8-.32.8-1.81v-3.05c0-.45-.18-.74-.5-.98-.16-.11-.3-.23-.3-.45,0-.26.13-.37.51-.55.59-.29,1.59-.58,1.84-.59.27,0,.39.16.39.48v.96c.61-.96,1.59-1.46,2.62-1.46,1.83,0,2.52,1.19,2.94,3.31l.55,2.75h0Z"
        />
      </g>
    </g>
    <g>
      <path
        style={{fill: themeColors.white}}
        d="M9.66,5.16c-.08-1.89-1.3-2.9-3.45-2.9-2.01,0-3.29,1.01-3.29,2.6,0,1.2.67,1.85,2.23,2.17l2.54.55c1.32.28,2.09.63,2.68,1.18.63.63.95,1.44.95,2.41,0,2.45-1.97,4.08-4.95,4.08-1.87,0-3.31-.61-4.22-1.76-.59-.77-.85-1.64-.89-3.02h1.36c.04,2.35,1.36,3.63,3.81,3.63,2.15,0,3.53-1.1,3.53-2.82,0-1.34-.79-2.05-2.8-2.47l-2.43-.53c-2.15-.47-3.16-1.56-3.16-3.41,0-2.31,1.78-3.75,4.68-3.75s4.6,1.4,4.77,4.06h-1.36Z"
      />
      <path
        style={{fill: themeColors.white}}
        d="M23.27,5.24c-.47-1.87-1.99-2.98-4.04-2.98-2.9,0-4.7,2.29-4.7,5.98s1.81,5.84,4.66,5.84c2.31,0,3.95-1.52,4.34-4h1.32c-.49,3.27-2.56,5.15-5.68,5.15-3.71,0-6-2.7-6-7.06s2.35-7.08,6.12-7.08c2.8,0,4.83,1.56,5.37,4.14h-1.4Z"
      />
      <path
        style={{fill: themeColors.white}}
        d="M39.14,8.26c0,4.14-2.56,6.98-6.27,6.98s-6.27-2.84-6.27-7.08,2.51-7.06,6.25-7.06,6.29,2.8,6.29,7.16ZM29.18,4.02c-.79.99-1.22,2.45-1.22,4.14,0,3.57,1.95,5.92,4.91,5.92s4.91-2.35,4.91-5.84c0-1.76-.41-3.18-1.22-4.22-.85-1.09-2.25-1.76-3.71-1.76s-2.78.65-3.67,1.76Z"
      />
      <path
        style={{fill: themeColors.white}}
        d="M51.69,1.41v8.74c0,1.74-.32,2.74-1.18,3.61-.89.91-2.21,1.38-3.81,1.38s-2.9-.49-3.81-1.38c-.85-.87-1.18-1.87-1.18-3.61V1.41h1.36v8.84c0,1.2.22,1.97.71,2.56.65.77,1.68,1.18,2.92,1.18s2.27-.41,2.92-1.18c.49-.59.71-1.36.71-2.56V1.41h1.36Z"
      />
      <path
        style={{fill: themeColors.white}}
        d="M59.17,14.93h-1.36V2.56h-4.3v-1.16h9.96v1.16h-4.3v12.37Z"
      />
    </g>
    <g>
      <path
        style={{fill: themeColors.white}}
        d="M2.02,20.72h.73v3.17c.51-.76,1.11-1.1,1.97-1.1,1.52,0,2.5,1.17,2.5,3.02s-1.02,3.13-2.59,3.13c-.82,0-1.39-.33-1.89-1.08v.92h-.73v-8.06ZM2.75,25.83c0,1.56.69,2.47,1.87,2.47s1.87-.91,1.87-2.44-.7-2.43-1.87-2.43-1.87.92-1.87,2.4Z"
      />
      <path
        style={{fill: themeColors.white}}
        d="M8.4,22.94l1.39,3.64c.25.7.33.93.42,1.4.1-.43.21-.77.47-1.41l1.35-3.63h.76l-2.59,6.6c-.37.95-.71,1.24-1.45,1.24-.16,0-.29-.01-.51-.05v-.63c.18.02.28.04.41.04.41,0,.63-.18.86-.75l.31-.77-2.22-5.68h.79Z"
      />
    </g>
  </svg>
);

export default Wordmark;
