import jwt from "jsonwebtoken";

let apiUrl = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  apiUrl = window._env_.API_URL;
}

const AuthProvider = {
  login: async ({ username, password, ...rest }) => {
    const request = new Request(`${apiUrl}/login_check`, {
      method: "POST",
      body: JSON.stringify({ email: username.toLowerCase(), password: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status === 401) {
          throw {
            error: "Email and Password do not match",
            statusCode: response.statusCode,
          };
        }
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        const { roles } = jwt.decode(token);
        if (
          roles.indexOf("ROLE_ADMIN") === -1 &&
          roles.indexOf("ROLE_DATA_MANAGER") === -1 &&
          roles.indexOf("ROLE_SUPER_ADMIN") === -1
        ) {
          return Promise.reject();
        }
        localStorage.setItem("token", token);
        localStorage.setItem("role", roles[0]);
      })
      .catch((error) => error);
  },
  logout: () => {
    localStorage.removeItem("token") ? Promise.resolve() : Promise.reject();
    window.location.href = "/login";
  },
  checkAuth: async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const { exp } = jwt.decode(token);
      if (exp > new Date().getTime() / 1000 - 10) {
        return Promise.resolve();
      }
    }
    return Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default AuthProvider;
