import React, {cloneElement} from "react";
import {
  CreateGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";

import {
  ArrayInput,
  ChipField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  AutocompleteInput,
  useListContext,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";
import CustomPagination from "../customComponents/Pagination";
import {RolePageProvider} from "../shared/RolePageProvider";

export const ClientImporterList = (props) => {
  const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
    } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={total}
        />
      </TopToolbar>
    );
  };
  return (
    <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
      <ListGuesser {...props} pagination={<CustomPagination />} actions={<ListActions />}>
        <ReferenceField
          source="client"
          reference="clients"
          label="Client"
          sortBy="client.name"
        >
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField
          source="importer"
          reference="importers"
          label="Importer"
          sortBy="importer.name"
        >
          <ChipField source="name" />
        </ReferenceField>
        <FieldGuesser source="importerValues" label="Importer Values" />
      </ListGuesser>
    </RolePageProvider>
  );
}

export const ClientImporterCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/client_importers");
    refresh();
  };
  return (
    <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
      <CreateGuesser onSuccess={onSuccess} {...props}>
        <ReferenceInput
          label="Client"
          source="client"
          reference="clients"
          validate={[required()]}
          filterToQuery={searchText => ({ name: searchText })}
          filter={{ pagination: false }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          label="Importer"
          source="importer"
          reference="importers"
          validate={[required()]}
          filter={{ pagination: false }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ArrayInput source={"importerValues"}>
          <SimpleFormIterator>
            <TextInput source={"key"} />
            <TextInput source={"value"} />
          </SimpleFormIterator>
        </ArrayInput>
      </CreateGuesser>
    </RolePageProvider>
  );
};

export const ClientImporterEdit = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <EditGuesser {...props}>
      <ReferenceInput
        label="Client"
        source="client"
        reference="clients"
        validate={[required()]}
        filterToQuery={searchText => ({ name: searchText })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Importer"
        source="importer"
        reference="importers"
        validate={[required()]}
        filter={{ pagination: false }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ArrayInput source={"importerValues"}>
        <SimpleFormIterator>
          <TextInput source={"key"} />
          <TextInput source={"value"} />
        </SimpleFormIterator>
      </ArrayInput>
    </EditGuesser>
  </RolePageProvider>
);

export const ClientImporterShow = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <ShowGuesser {...props}></ShowGuesser>
  </RolePageProvider>
);
