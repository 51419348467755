import React from "react";
import { Pagination } from "react-admin";
import styled from "@emotion/styled";

const RAPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const CustomPagination = styled(RAPagination)`
  .page-number:not([class*="MuiButton-textPrimary"]) {
    font-weight: bold;
    background-color: #0d2d3d;
    color: white;
  }
`;

export default CustomPagination;
