import React, { cloneElement, Fragment, useState } from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  SaveButton,
  ReferenceArrayInput,
  Toolbar,
  SelectArrayInput,
  DateField,
  SimpleForm,
  Edit,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Button,
  useDataProvider,
  required,
  useNotify,
  useRedirect,
  useRefresh,
  Confirm,
  BooleanField,
  BooleanInput,
  sanitizeListRestProps,
  TopToolbar,
  CreateButton,
  ExportButton,
  Filter,
  TextInput,
} from "react-admin";

import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";

import CustomPagination from "../customComponents/Pagination";

const RunImporterButton = ({ record }) => {
  const dataProvider = useDataProvider();

  const runImporter = () => {
    return dataProvider.create("queue_importers", {
      data: {
        clientId: record.id.split("/")[2],
        fieldId: null,
        clearData: false,
      },
    });
  };

  return (
    <Button onClick={runImporter} label={"Run Importers"}>
      <SystemUpdateIcon />
    </Button>
  );
};

const ClearDataAndImportButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);

  const runImporter = () => {
    setOpen(false);
    return dataProvider.create("queue_importers", {
      data: {
        clientId: record.id.split("/")[2],
        fieldId: null,
        clearData: true,
      },
    });
  };

  const handleClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = (e) => {
    setOpen(false);
    e.stopPropagation();
  };

  return (
    <Fragment>
      <Button onClick={handleClick} label="Delete and Reimport" key="button" />
      <Confirm
        isOpen={open}
        title="Delete and Reload?"
        content={`Delete and Reload data for ${record.name}.  This will delete all existing Data Points and immediately queue importers to run`}
        onConfirm={runImporter}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const ListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  showOnlyEnabled,
  onToggleEnabled,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={total}
      />
      <Button
        onClick={() => onToggleEnabled()}
        label={showOnlyEnabled ? "Show Disabled" : "Show Enabled"}
      />
    </TopToolbar>
  );
};

export const ClientList = (props) => {
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);

  return (
    <ListGuesser
      {...props}
      filter={{ enabled: showOnlyEnabled }}
      actions={
        <ListActions
          showOnlyEnabled={showOnlyEnabled}
          onToggleEnabled={() => setShowOnlyEnabled(!showOnlyEnabled)}
        />
      }
      bulkActionButtons={false}
      filters={
        <Filter {...props}>
          <TextInput label="Name" source="name" />
          <ReferenceArrayInput
            style={{ minWidth: "200px" }}
            label="Account Managers"
            source="accountManagers"
            reference="users"
            filter={{ hasRole: "ROLE_ACCOUNT_MANAGER", pagination: false }}
          >
            <SelectArrayInput optionText="email" />
          </ReferenceArrayInput>
          <BooleanInput
            label="Seller/Vendor Client"
            source="sellerCentralEnabled"
          />
        </Filter>
      }
      pagination={<CustomPagination />}
    >
      <FieldGuesser source={"name"} />
      {/*<ReferenceArrayField*/}
      {/*  source="accountManagers"*/}
      {/*  reference="users"*/}
      {/*  label="Account Managers"*/}
      {/*  sortable={false}*/}
      {/*>*/}
      {/*  <SingleFieldList>*/}
      {/*    <ChipField source="fullName" />*/}
      {/*  </SingleFieldList>*/}
      {/*</ReferenceArrayField>*/}
      <ReferenceArrayField
        source="users"
        reference="users"
        label="Users"
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="email" />
        </SingleFieldList>
      </ReferenceArrayField>
      <BooleanField
        source={"sellerCentralEnabled"}
        label="Seller Central Enabled"
        sortable={false}
      />
      <BooleanField
        source={"vendorCentralEnabled"}
        label="Vendor Central Enabled"
        sortable={false}
      />
      <FieldGuesser source={"clientKey"} label="Client Key" />
      <FieldGuesser source={"createdAt"} label="Created" />
      <FieldGuesser source={"updatedAt"} label="Updated" />
      <RunImporterButton />
      <ClearDataAndImportButton />
    </ListGuesser>
  );
};

export const ClientCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    redirect("/clients");
    refresh();
  };
  return (
    <CreateGuesser onSuccess={onSuccess} {...props}>
      <InputGuesser source={"name"} validate={[required()]} />
      <ReferenceArrayInput
        label="Account Managers"
        source="accountManagers"
        reference="users"
        filter={{
          enabled: true,
          role: "ROLE_ACCOUNT_MANAGER",
          pagination: false,
        }}
        validate={[required()]}
      >
        <SelectArrayInput optionText="email" />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        label="Client Users"
        source="users"
        reference="users"
        filter={{ pagination: false, enabled: true, role: "ROLE_CLIENT_USER" }}
      >
        <SelectArrayInput optionText="email" />
      </ReferenceArrayInput>
      <InputGuesser
        source={"clientKey"}
        label={"Client Key"}
        validate={[required()]}
      />
      <InputGuesser source={"enabled"} defaultValue={true} />
    </CreateGuesser>
  );
};

const ClientDetailTitle = ({ record }) => (
  <span>Client: {record ? record.name : ""}</span>
);

export const ClientEdit = (props) => {
  const CustomToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  return (
    <Edit {...props} title={<ClientDetailTitle />}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <InputGuesser source={"name"} validate={[required()]} />
        <ReferenceArrayInput
          label="Account Managers"
          source="accountManagers"
          reference="users"
          filter={{
            role: "ROLE_ACCOUNT_MANAGER",
            pagination: false,
            enabled: true,
          }}
          validate={[required()]}
        >
          <SelectArrayInput optionText="email" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          label="Client Users"
          source="users"
          reference="users"
          filter={{
            role: "ROLE_CLIENT_USER",
            pagination: false,
            enabled: true,
          }}
        >
          <SelectArrayInput optionText="email" />
        </ReferenceArrayInput>
        <InputGuesser
          source={"clientKey"}
          validate={[required()]}
          label={"Client Key"}
        />
        <DateField source={"createdAt"} addLabel={true} showTime={true} />
        <DateField source={"updatedAt"} addLabel={true} showTime={true} />
        <InputGuesser source={"enabled"} defaultValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export const ClientShow = (props) => (
  <ShowGuesser {...props} title={<ClientDetailTitle />}>
    <FieldGuesser source={"name"} addLabel={true} />
    <ReferenceArrayField
      source="accountManagers"
      reference="users"
      label="Account Managers"
      sortable={false}
      filter={{ enabled: true }}
    >
      <SingleFieldList>
        <ChipField source="email" />
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField source="users" reference="users" label="Users">
      <SingleFieldList>
        <ChipField source="email" />
      </SingleFieldList>
    </ReferenceArrayField>
    <FieldGuesser source={"clientKey"} addLabel={true} label={"Client Key"} />
    <BooleanField source={"sellerCentralEnabled"} />
    <BooleanField source={"vendorCentralEnabled"} />
    <DateField source={"createdAt"} addLabel={true} showTime={true} />
    <DateField source={"updatedAt"} addLabel={true} showTime={true} />
  </ShowGuesser>
);
