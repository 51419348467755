import React, {cloneElement} from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  ArrayInput,
  ArrayField,
  ChipField,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  Button,
  useDataProvider,
  required,
  BooleanInput,
  BooleanField,
  useListContext,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
} from "react-admin";

import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";

import { extractUUID } from "../../utils";
import CustomPagination from "../customComponents/Pagination";
import {RolePageProvider} from "../shared/RolePageProvider";

const RunImporterButton = ({ record }) => {
  const dataProvider = useDataProvider();

  const runImporter = () => {
    return dataProvider.create("queue_importers", {
      data: {
        clientId: null,
        fieldId: extractUUID(record.id),
      },
    });
  };

  return (
    <Button onClick={runImporter} label={"Run Importers"}>
      <SystemUpdateIcon />
    </Button>
  );
};

export const FieldConfigurationList = (props) => {
  const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      ...rest
    } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath} />

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={total}
        />
      </TopToolbar>
    );
  };
  return (
    <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
      <ListGuesser {...props} pagination={<CustomPagination />} actions={<ListActions />}>
        <FieldGuesser source={"displayName"} label="Display Name" />
        <FieldGuesser source={"fieldKey"} label="Field Key" />
        <ReferenceField
          source="importer"
          reference="importers"
          label="Importer"
          sortBy="importer.name"
        >
          <ChipField source="name" />
        </ReferenceField>
        <FieldGuesser source={"fieldType"} label="Field Type" />
        <FieldGuesser source={"calculationString"} label="Calculation String" />
        <FieldGuesser source={"addZeroes"} label="Add Zeroes" />
        <FieldGuesser source={"graphEnabled"} label="Graph Enabled" />
        <RunImporterButton />
      </ListGuesser>
    </RolePageProvider>
  );
}

export const FieldConfigurationCreate = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <CreateGuesser {...props}>
      <InputGuesser source={"displayName"} validate={[required()]} />
      <InputGuesser source={"fieldKey"} validate={[required()]} />
      <ReferenceInput
        label="Importer"
        source="importer"
        reference="importers"
        validate={[required()]}
        filter={{ pagination: false }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ArrayInput source={"sourceFields"}>
        <SimpleFormIterator>
          <TextInput source={"label"} label={"Label"} />
          <TextInput source={"tableName"} label={"Table"} />
          <BooleanInput source={"isFullTableName"} label={"Full Table Name"} />
          <TextInput source={"field"} label={"Field"} />
          <TextInput source={"toNumberFormat"} label={"to_number() Format"} />
          <ArrayInput source={"joinFields"} label={"Join Fields"}>
            <SimpleFormIterator>
              <TextInput source={"left"} label={"left"} />
              <TextInput source={"right"} label={"right"} />
            </SimpleFormIterator>
          </ArrayInput>
          <SelectInput
            source="aggregateOperation"
            label="Aggregate Operation"
            allowEmpty="true"
            choices={[
              { id: "sum", name: "Sum" },
              { id: "avg", name: "Average" },
              { id: "count", name: "Count" },
              { id: "max", name: "Max" },
            ]}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={"importCriteria"}>
        <SimpleFormIterator>
          <TextInput source={"label"} label={"Label"} />
          <TextInput source={"type"} label={"Type"} />
          <TextInput source={"tableName"} label={"Table"} />
          <TextInput source={"field"} label={"Field"} />
          <ArrayInput source={"values"} label={"Values"}>
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source={"fieldType"} validate={[required()]} />
      <InputGuesser source={"calculationString"} />
      <InputGuesser source={"addZeroes"} />
      <InputGuesser source={"axisLabel"} />
      <SelectInput
        source="axisName"
        label="Axis Name"
        allowEmpty="true"
        choices={[
          { id: "left", name: "Primary" },
          { id: "right", name: "Secondary" },
        ]}
      />
      <InputGuesser source={"graphEnabled"} />
    </CreateGuesser>
  </RolePageProvider>
);

export const FieldConfigurationEdit = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <EditGuesser {...props}>
      <InputGuesser source={"displayName"} validate={[required()]} />
      <InputGuesser source={"fieldKey"} validate={[required()]} />
      <ReferenceInput
        label="Importer"
        source="importer"
        reference="importers"
        validate={[required()]}
        filter={{ pagination: false }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ArrayInput source={"sourceFields"}>
        <SimpleFormIterator>
          <TextInput source={"label"} label={"Label"} />
          <TextInput source={"tableName"} label={"Table"} />
          <BooleanInput source={"isFullTableName"} label={"Full Table Name"} />
          <TextInput source={"field"} label={"Field"} />
          <TextInput source={"toNumberFormat"} label={"to_number() Format"} />
          <ArrayInput source={"joinFields"} label={"Join Fields"}>
            <SimpleFormIterator>
              <TextInput source={"left"} label={"left"} />
              <TextInput source={"right"} label={"right"} />
            </SimpleFormIterator>
          </ArrayInput>
          <SelectInput
            source="aggregateOperation"
            label="Aggregate Operation"
            allowEmpty="true"
            choices={[
              { id: "sum", name: "Sum" },
              { id: "avg", name: "Average" },
              { id: "count", name: "Count" },
              { id: "max", name: "Max" },
            ]}
          />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source={"importCriteria"}>
        <SimpleFormIterator>
          <TextInput source={"label"} label={"Label"} />
          <TextInput source={"type"} label={"Type"} />
          <TextInput source={"tableName"} label={"Table"} />
          <TextInput source={"field"} label={"Field"} />
          <ArrayInput source={"values"} label={"Values"}>
            <SimpleFormIterator>
              <TextInput />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source={"fieldType"} validate={[required()]} />
      <InputGuesser source={"calculationString"} />
      <InputGuesser source={"addZeroes"} />
      <InputGuesser source={"axisLabel"} />
      <SelectInput
        source="axisName"
        label="Axis Name"
        allowEmpty="true"
        choices={[
          { id: "left", name: "Primary" },
          { id: "right", name: "Secondary" },
        ]}
      />
      <InputGuesser source={"graphEnabled"} />
    </EditGuesser>
  </RolePageProvider>
);

export const FieldConfigurationShow = (props) => (
  <RolePageProvider allowed={['ROLE_DATA_MANAGER']}>
    <ShowGuesser {...props}>
      <TextField source={"displayName"} />
      <TextField source={"fieldKey"} />
      <ReferenceField source="importer" reference="importers" label="Importer">
        <ChipField source="name" />
      </ReferenceField>
      <ArrayField source={"sourceFields"}>
        <Datagrid>
          <TextField source={"label"} />
          <TextField source={"tableName"} />
          <BooleanField source={"isFullTableName"} />
          <TextField source={"field"} />
          <TextField source={"aggregateOperation"} />
          <TextField source={"toNumberFormat"} />
        </Datagrid>
      </ArrayField>
      <ArrayField source={"importCriteria"}>
        <Datagrid>
          <TextField source={"label"} />
          <TextField source={"type"} label={"Type"} />
          <TextField source={"tableName"} />
          <TextField source={"field"} />
        </Datagrid>
      </ArrayField>
      <TextField source={"fieldType"} />
      <TextField source={"calculationString"} />
      <FieldGuesser source={"addZeroes"} addLabel={true} />
      <TextField source={"axisLabel"} />
      <TextField source={"axisName"} />
      <FieldGuesser source={"graphEnabled"} addLabel={true} />
    </ShowGuesser>
  </RolePageProvider>
);
