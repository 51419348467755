export const extractUUID = (id) => id.split("/")[2];
let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  entrypoint = window._env_.API_URL;
}
export const post = (url, body, token = "") =>
  fetch(`${entrypoint}${url}`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      ...(token && { authorization: `Bearer ${token}` }),
    },
    body,
  });

export const get = (url, token = "") =>
  fetch(`${entrypoint}${url}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      ...(token && { authorization: `Bearer ${token}` }),
    },
  });
export const requestPasswordReset = async (password, token) => {
  const response = await post(
    apiRoutes.forgotPassword.replace("{token}", token),
    JSON.stringify({ password })
  );

  return response.status;
};
export const requestPasswordResetEmail = async (email) => {
  const response = await post(
    apiRoutes.forgotPassword.replace("{token}", ""),
    JSON.stringify({ email })
  );

  return response.status;
};
export const validatePasswordResetToken = async (token) => {
  const response = await get(
    apiRoutes.forgotPassword.replace("{token}", token)
  );

  return response.status;
};
export const dashboardRoutes = {
  asins: "/asins",
  finance: "/finance",
  inventory: "/inventory",
  marketing: "/marketing",
  reporting: "/reporting",
  sales: "/sales",
};

export const routes = {
  login: "/login",
  join: "/join/:token?",
  resetPassword: "/password_reset/:token?",
  account: "/account",
  history: "/history",
  dashboard: dashboardRoutes,
};

export const apiRoutes = {
  forgotPassword: "/forgot-password/{token}",
  graphQl: "/graphql",
  login: "/login_check",
  refreshToken: "/token/refresh",
};

export const  formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}
