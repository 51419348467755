import React from "react";
export const UpArrow = ({ height, width, fill, margin, onClick }) => (
  <div
    style={{
      height: height || "50px",
      width: width || "50px",
      margin: margin || "auto",
      cursor: onClick ? "pointer" : "auto",
      opacity: onClick ? 1 : 0.1,
    }}
    onClick={onClick}
    role="button"
  >
    <svg
      height={height || "50px"}
      width={height || "50px"}
      viewBox="0 0 500 500"
    >
      <path
        d="M 110.126 67.267 L 357.548 67.267 L 357.548 329.041 L 110.126 329.041 Z"
        fill="none"
      />
      <path
        fill={onClick ? fill || "#000" : "#000000"}
        d="M 48.033 204.278 L 164.047 204.377 L 163.91 363.735 L 337.931 363.885 L 338.068 204.527 L 454.083 204.626 L 251.217 18.535 L 48.033 204.278 Z M 453.349 453.82 L 453.673 405.84 L 45.87 405.165 L 45.538 454.703 L 453.349 453.82 Z"
      />
    </svg>
  </div>
);
