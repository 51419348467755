import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Button,
  TextField,
  Typography,
  ThemeProvider,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import Wordmark from "./Wordmark";
import { loginFormTheme, withFormStyles } from "./styles";
import {
  requestPasswordReset,
  requestPasswordResetEmail,
  validatePasswordResetToken,
  routes,
} from "../../utils";

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const emailErrorMessage = "Please enter a valid email address";

const Container = styled.div`
  ${withFormStyles()}
`;

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isValidToken, setIsValidToken] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    const validateToken = async () => {
      const response = await validatePasswordResetToken(token);
      if (response === 200) {
        setIsValidToken(true);
      }
    };

    if (token) {
      validateToken();
    }
  }, [token]);

  const handleEmailFieldBlur = (e) => {
    if (e.target.value === "") {
      setEmailError("");
    } else if (!emailRegex.test(e.target.value)) {
      setEmailError(emailErrorMessage);
    } else {
      setEmailError("");
    }
  };

  const handleRequestPasswordResetEmail = async () => {
    const response = await requestPasswordResetEmail(email);
    if (response === 204) {
      createNotification(
        "An email with instructions will be sent to the address you provided."
      );
      setTimeout(() => history.push(routes.login), 2500);
    } else if (response === 400) {
      const message = "There was an error with the request.";
      createNotification(message);
    }
  };

  const handleRequestPasswordReset = async () => {
    const response = await requestPasswordReset(newPassword, token);
    if (response === 204) {
      createNotification("Password updated");
      setTimeout(() => history.push(routes.login), 2500);
    } else if (response === 400) {
      createNotification(
        "Please fill in New Password and Confirm Password fields"
      );
    } else if (response === 404) {
      createNotification("Invalid Reset Password token");
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isValidToken) {
      handleRequestPasswordReset();
    } else {
      handleRequestPasswordResetEmail();
    }
  };

  const createNotification = (message) => {
    setAlertMessage(message);
    setShowAlertMessage(true);
  };

  const removeNotification = () => {
    setShowAlertMessage(false);
    setAlertMessage("");
  };

  return (
    <ThemeProvider theme={loginFormTheme}>
      <CssBaseline>
        <Container>
          <Typography variant="h1">Brandwoven</Typography>
          <Wordmark />
          <form>
            {isValidToken ? (
              <>
                <TextField
                  type="password"
                  fieldKey="newPassword"
                  id="new-password"
                  label="New Password"
                  value={newPassword}
                  onChange={(key, value) => setNewPassword(value)}
                />
                <TextField
                  type="password"
                  fieldKey="confirmPassword"
                  id="confirm-password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(key, value) => setConfirmPassword(value)}
                />
              </>
            ) : (
              <TextField
                error={emailError !== ""}
                helperText={emailError}
                inputProps={{ "aria-label": "Email" }}
                label="Email"
                onBlur={handleEmailFieldBlur}
                onChange={(e) => setEmail(e.target.value)}
                required
                value={email}
                variant="outlined"
              />
            )}
            <Button
              disabled={
                isValidToken
                  ? newPassword === "" ||
                    confirmPassword === "" ||
                    newPassword !== confirmPassword
                  : email === "" || !emailRegex.test(email)
              }
              disableRipple
              onClick={handleButtonClick}
              type="submit"
              variant="outlined"
            >
              Submit
            </Button>
            <Typography variant="body1">
              <Link component={RouterLink} to={routes.login} underline="none">
                Login
              </Link>
            </Typography>
            <Typography variant="body1" className="info">
              <span className="emphasize">Don&apos;t have an account?</span>
              <span>Reach out to your client success manager at Brandwoven</span>
            </Typography>
          </form>
          <Snackbar
            open={showAlertMessage}
            autoHideDuration={6000}
            onClose={removeNotification}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={removeNotification}
              severity="success"
            >
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default ResetPassword;
