import React from 'react';
import { Redirect } from 'react-router-dom';

export const RolePageProvider = ({
 allowed,
 redirectTo,
 children,
}) => {
  const role = window.localStorage.getItem("role");
  if (
    role === null ||
    allowed.find(_allowedRole => _allowedRole === role) === undefined
  ) {
    return <Redirect to={redirectTo ? redirectTo : '/'} />;
  } else {
    return <>{children}</>;
  }
};
