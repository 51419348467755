import React, { useEffect, useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import { UpArrow } from "./UpArrow.js";
import {
  useDataProvider,
  Button,
  SelectInput,
  SimpleForm,
  Title,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";

import { Table, Container } from "@material-ui/core";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import { useNotify } from "react-admin";
import DialogContent from "@material-ui/core/DialogContent";
import styled from "@emotion/styled";
let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  entrypoint = window._env_.API_URL;
}

const useStyles = makeStyles({
  // loadFileButton: {
  //   margin: "15px auto auto",
  //   borderRadius: "30px",
  //   padding: "10px 30px",
  // },
  // saveFileButton: {
  //   margin: "10px 0",
  //   borderRadius: "30px",
  //   padding: "10px 30px",
  // },
  // removeFileButton: {
  //   margin: "10px",
  //   borderRadius: "30px",
  //   padding: "10px 30px",
  //   textDecoration: "underline !important",
  // },
  noData: {
    textAlign: "center",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  // instructions: {
  //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //   color: "#0d2d3d",
  //   lineHeight: 1.2,
  //   fontSize: "14px",
  // },

  // instructionsList: {
  //   padding: "0 20px",
  // },
  // instructionsTitle: {
  //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //   color: "#0d2d3d",
  //   fontWeight: "bold",
  //   fontSize: "16px",
  // },
  // instructionsWrapper: {
  //   width: "495px",
  //   padding: "10px 0",
  // },
  // dialogContent: {
  //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

  //   padding: "40px 20px 20px",
  //   color: "#0d2d3d",
  // },
  // dialogButtons: {
  //   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  //   marginBotton: "10px",
  //   padding: "20px 40px 10px",
  //   color: "#0d2d3d",
  //   display: "flex",
  //   flexDirection: "column",
  // },
  // dialogButtonsWrapper: {
  //   margin: "40px auto 0",
  //   flexDirection: "column",
  // },
});

const Form = styled(SimpleForm)`
  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    max-width: 475px;
    align-items: flex-start;
  }
  .MuiFormLabel-root {
    color: #0d2d3d;
  }
  .MuiSvgIcon-root {
    fill: #0d2d3d;
  }
  .MuiSelect-outlined {
    background: white;
    padding: 15px;
  }
  .MuiOutlinedInput-root:not(.Mui-focused) {
      .MuiSelect-outlined {
        background: white;
      }
    }
  }
.file-area {
    background: white;
    width: 450px;
    height: 230px;
    border: 2px dashed #0d2d3d;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: column;
    margin-top: 20px;
}
`;

const StyledTableContainer = styled(TableContainer)`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid;
  max-width: 1000px;
  max-height: 400px;
  margin-top: 20px;
  .MuiTableRow-head {
    background: #dbe0e2;
  }
  .MuiTableCell-root {
    border-bottom: 1px solid black;
  }
  .MuiTableRow-root:last-of-type {
    .MuiTableCell-root {
      border-bottom: none !important;
    }
  }
`;
const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 30px 20px 15px;
    max-width: 475px;
  }
  .MuiSvgIcon-root {
    fill: #f84878;
    width: 100px;
    height: 100px;
    margin: auto;
  }
`;
const BulkUpload = styled(Container)`
  text-align: left !important;
`;
export const BulkUploadView = () => {
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [fileDataHeaders, setFileDataHeaders] = useState([]);
  const [fileDataRows, setFileDataRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dataProvider = useDataProvider();
  const inputFile = useRef(null);
  const notify = useNotify();
  const classes = useStyles();
  const handleSubmit = async (event) => {
    let data = new FormData();
    await data.append("file", inputFile.current.state.files[0]);
    await data.append("clientId", client);
    setLoading(true);
    try {
      const response = await fetch(`${entrypoint}/category/uploadCSV`, {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        setIsDialogOpen(false);
        clearFile();
        notify("CSV has been successfully uploaded.", "info");
      } else {
        throw response;
      }
    } catch (error) {
      notify("An error occured while uploading the CSV.", "warning");
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    dataProvider
      .getList("clients", {
        pagination: {},
        sort: { field: "name", order: "ASC" },
        filter: { pagination: false },
      })
      .then(({ data }) => {
        const dataRecords = Object.values(data);
        if (dataRecords.length > 0) {
          setClients(dataRecords);
        }
      });
  }, [dataProvider]);

  useEffect(() => {
    if (fileData.length) {
      const headers = fileData[0].data;
      const rows = fileData
        .map((row, i) => {
          if (i > 0) {
            return row.data;
          }
        })
        .filter((row) => row !== undefined);
      setFileDataHeaders(headers);
      setFileDataRows(rows);
    }
  }, [fileData]);
  const clearFile = () => {
    setFileDataHeaders([]);
    setFileDataRows([]);
    setFileData([]);
  };
  return (
    <BulkUpload className="bulk-upload-page">
      <div
        style={{
          width: "495px",
          padding: "10px 0 ",
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          color: "#0d2d3d",
          marginLeft: "10px",
        }}
        // className={classes.instructionsWrapper}
      >
        <Title title="Bulk Upload" defaultTitle="Bulk Upload" />
        <p
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: "#0d2d3d",
            fontWeight: "bold",
            fontSize: "16px",
          }}
          // className={classes.instructionsTitle}
        >
          UPLOAD INSTRUCTIONS
        </p>
        <div
          // className={classes.instructions}
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: "#0d2d3d",
            lineHeight: "1.2",
            fontSize: "14px",
          }}
        >
          <ol
            style={{
              padding: "0 20px",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: "#0d2d3d",
              lineHeight: "1.2",
            }}
            // className={classes.instructionsList}
          >
            <li>Select the client you want to update.</li>
            <br />
            <li>Select the CSV formatted file you want to upload.</li>
            <br />
            <li>
              Your CSV should be formatted with the following headers in exact
              order:
              <br />
              <span>Category, Subcategory, Parent, ASIN, Product Name.</span>
            </li>
            <br />
            <li>
              After selecting your CSV, the data should load automatically in
              the preview.
            </li>
            <br />
            <li>
              After you verify your data is correct in the preview, press the
              "SAVE BULK UPLOAD" button.
            </li>
            <br />
            <li>
              if you wish to cancel, press the "CANCEL", button to clear the
              CSV.
            </li>
          </ol>
        </div>
      </div>
      <Form
        style={{ display: fileData.length ? "none" : "block" }}
        classes={classes}
        noValidate
        toolbar={null}
      >
        <SelectInput
          source="client"
          label="Select a Client"
          onChange={(e) => setClient(e.target.value)}
          choices={clients.map((client) => ({
            id: client.id,
            name: client.name,
          }))}
          variant="outlined"
          margin="none"
        />
        <CSVReader
          ref={inputFile}
          onFileLoad={(data) => setFileData(data)}
          onError={(error) => console.log(error)}
          className="csv-reader"
          style={{}}
          addRemoveButton
          // noDrag
        >
          {() => (
            <aside>
              <div className="file-area">
                <UpArrow
                  onClick={client ? (e) => inputFile.current.open(e) : null}
                  height="75px"
                  width="75px"
                  fill="#0d2d3d"
                  margin="auto auto 0"
                />
                <Button
                  disableRipple
                  variant="contained"
                  color="primary"
                  onClick={(e) => inputFile.current.open(e)}
                  label={"BROWSE LOCAL"}
                  disabled={client === ""}
                  style={{
                    margin: "15px auto auto",
                    borderRadius: "30px",
                    padding: "10px 30px",
                  }}
                />
              </div>
            </aside>
          )}
        </CSVReader>
      </Form>
      {(fileData.length && (
        <>
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsDialogOpen(true)}
              label="SAVE BULK UPLOAD"
              disabled={client === "" || fileData.length === 0}
              style={{
                margin: "10px 0",
                borderRadius: "30px",
                padding: "10px 30px",
              }}
              // className={classes.saveFileButton}
            />
            <Button
              disableRipple={true}
              variant="text"
              color="primary"
              onClick={clearFile}
              label="CANCEL AND REMOVE CSV"
              // className={classes.removeFileButton}
              style={{
                margin: "10px",
                borderRadius: "30px",
                padding: "10px 30px",
                textDecoration: "underline",
              }}
            />
          </span>
          <StyledTableContainer>
            {fileData.length &&
            fileDataHeaders.length &&
            fileDataRows.length ? (
              <Table aria-label="file content">
                <TableHead>
                  <TableRow style={{ borderBottom: "1px solid #0d2d3d" }}>
                    {fileDataHeaders.map((header) => (
                      <TableCell key={`header-${header}`}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fileDataRows?.map((row, i) => (
                    <TableRow key={`data-row-${i}`}>
                      {row.map((value, j) => (
                        <TableCell key={`data-cell-${i}-${j}`}>
                          {value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <p className={classes.noData}>No data to display</p>
            )}
          </StyledTableContainer>
        </>
      )) ||
        null}
      <StyledDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <WarningIcon />
        <DialogContent
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            padding: "40px 20px 20px",
            color: "#0d2d3d",
            fontWeight: 100,
          }}
          // className={classes.dialogContent}
        >
          WARNING: YOU CANNOT UNDO THIS ACTION! Any categories, subcategories,
          or products referenced in your CSV will be altered. Any categories,
          subcategories, or products not referenced in your CSV will remain
          unchanged.
        </DialogContent>
        <DialogActions
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            marginBotton: "10px",
            padding: "20px 40px 10px",
            color: "#0d2d3d",
            display: "flex",
            flexDirection: "column",
            fontWeight: 100,
          }}
          // className={classes.dialogButtons}
        >
          Do you want to continue and change the client's data?
          <span
            style={{ margin: "40px auto 0", flexDirection: "column" }}
            // className={classes.dialogButtonsWrapper}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              label={loading ? "Uploading..." : "SAVE BULK UPLOAD"}
              className={classes.saveFileButton}
              style={{
                margin: "10px 0",
                borderRadius: "30px",
                padding: "10px 30px",
              }}
            />
            <Button
              disableRipple={true}
              variant="text"
              color="primary"
              onClick={() => setIsDialogOpen(false)}
              label="CANCEL"
              // className={classes.removeFileButton}
              style={{
                margin: "10px",
                borderRadius: "30px",
                padding: "10px 30px",
                textDecoration: "underline",
                fontWeight: 100,
              }}
            />
          </span>
        </DialogActions>
      </StyledDialog>
    </BulkUpload>
  );
};
