import React, { cloneElement, Fragment, useState } from "react";
import {
  CreateGuesser,
  InputGuesser,
  ShowGuesser,
  EditGuesser,
  ListGuesser,
  FieldGuesser,
} from "@api-platform/admin";
import {
  ReferenceInput,
  ReferenceField,
  ChipField,
  FormDataConsumer,
  useQuery,
  Error,
  required,
  Filter,
  useListContext,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  AutocompleteInput,
  TextInput,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { useForm } from "react-final-form";
import CustomPagination from "../customComponents/Pagination";
import { formatDate } from "../../utils";

const ClientCategoriesCreate = ({ formData, ...props }) => {
  const form = useForm();
  const categoryFilter = {
    pagination: false,
  };

  if (formData.client) {
    categoryFilter.client = {
      id: formData.client || "",
    };
  }

  return (
    <Fragment>
      <ReferenceInput
        allowEmpty={true}
        label="Client"
        source="client"
        reference="clients"
        validate={[required()]}
        onChange={() => {
          form.change("category", null);
        }}
        {...props}
        filterToQuery={(searchText) => ({ name: searchText })}
        filter={{ pagination: false }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        allowEmpty={true}
        label="Category"
        source="category"
        reference="categories"
        validate={[required()]}
        disabled={!formData.client}
        filter={categoryFilter}
        {...props}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Fragment>
  );
};

const ClientCategoriesEdit = ({ formData, ...props }) => {
  const { data, loading, error } = useQuery({
    type: "getOne",
    resource: "categories",
    payload: { id: formData.category },
  });

  if (loading) return null;
  if (error) return <Error />;
  if (!data) return null;

  return (
    <>
      <ReferenceInput
        allowEmpty={true}
        label="Client"
        source="client"
        reference="clients"
        disabled={true}
        {...props}
        initialValue={data.client}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        allowEmpty={false}
        label="Category"
        source="category"
        reference="categories"
        filter={{
          pagination: false,
          client: {
            id: data.client,
          },
        }}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </>
  );
};

const SubCategoryFilters = (props) => {
  const categoryFilter = {
    pagination: false,
  };
  const clientFilter = {
    pagination: false,
  };

  if (props.filterValues.category?.client) {
    if (typeof props.filterValues.category?.client === "string") {
      categoryFilter.client = {
        id: props.filterValues.category?.client || "",
      };
    }
  }
  const [clientFil, setClientFil] = useState("");
  return (
    <Filter {...props}>
      <ReferenceInput
        source="category.client"
        reference="clients"
        label="Client"
        filterToQuery={(searchText) => ({ client: searchText })}
        onChange={(e) => setClientFil(e)}
        filter={clientFilter}
        defaultValue={clientFil}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="category.id"
        reference="categories"
        filter={categoryFilter}
        label="Category"
      >
        <AutocompleteInput
          optionText={({ name, clientName }) =>
            `${name || ""} ${clientName ? "(" + clientName + ")" : ""}`
          }
        />
      </ReferenceInput>
      <TextInput source="name" label="Name" />
    </Filter>
  );
};

export const SubCategoryList = (props) => {
  const ListActions = (props) => {
    const { className, filters, maxResults, ...rest } = props;
    const {
      currentSort,
      resource,
      displayedFilters,
      filterValues,
      basePath,
      showFilter,
      total,
    } = useListContext();
    const exporter = (subcategories) => {
      const postsForExport = subcategories.map((subcategory) => {
        return {
          "Subcategory Name": subcategory.name,
          "Category Name": subcategory.categoryName,
          Client: subcategory.clientName,
        };
      });
      jsonExport(
        postsForExport,
        {
          headers: ["Subcategory Name", "Category Name", "Client"],
        },
        (err, csv) => {
          downloadCSV(csv, `scout-subcategories-${formatDate(new Date())}`);
        }
      );
    };
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        <CreateButton basePath={basePath} />

        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={total}
          exporter={exporter}
        />
      </TopToolbar>
    );
  };
  return (
    <ListGuesser
      {...props}
      filters={<SubCategoryFilters />}
      pagination={<CustomPagination />}
      actions={<ListActions />}
    >
      <FieldGuesser source={"name"} />
      <ReferenceField source="clientId" reference="clients" label={"Client"}>
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField
        source="category"
        reference="categories"
        label={"Category"}
        sortBy="category.name"
      >
        <ChipField source="name" />
      </ReferenceField>
    </ListGuesser>
  );
};

const SubCategoryDetailTitle = ({ record }) => (
  <span>
    Subcategory:{" "}
    {record
      ? `${record.clientName} - ${record.categoryName} - ${record.name}`
      : ""}
  </span>
);

export const SubCategoryCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"name"} validate={[required()]} />
    <FormDataConsumer>
      {(formDataProps) => <ClientCategoriesCreate {...formDataProps} />}
    </FormDataConsumer>
  </CreateGuesser>
);

export const SubCategoryEdit = (props) => (
  <EditGuesser {...props} title={<SubCategoryDetailTitle />}>
    <InputGuesser source={"name"} validate={[required()]} />
    <FormDataConsumer>
      {(formDataProps) => <ClientCategoriesEdit {...formDataProps} />}
    </FormDataConsumer>
  </EditGuesser>
);

export const SubCategoryShow = (props) => (
  <ShowGuesser {...props} title={<SubCategoryDetailTitle />}>
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"clientName"} label="Client" addLabel={true} />
    <ReferenceField source="category" reference="categories" label={"Category"}>
      <ChipField source="name" />
    </ReferenceField>
  </ShowGuesser>
);
