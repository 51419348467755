import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  HydraAdmin,
} from "@api-platform/admin";
import { ResourceGuesser } from "@api-platform/admin";
import authProvider from "./AuthProvider";
import CustomLayout from "./CustomLayout";
import CustomLogin from "./components/login/Login";
import ResetPassword from "./components/login/Reset";
import theme from "./theme";
import {dataProvider} from "./CustomDataProvider";

import {
  ClientList,
  ClientCreate,
  ClientEdit,
  ClientShow,
} from "./components/clients/Clients";

import {
  DataPointList,
  DataPointCreate,
  DataPointEdit,
  DataPointShow,
} from "./components/datapoints/DataPoints";

import {
  ImporterCreate,
  ImporterEdit,
  ImporterList,
  ImporterShow,
} from "./components/importers/Importers";

import {
  ClientImporterCreate,
  ClientImporterEdit,
  ClientImporterList,
  ClientImporterShow,
} from "./components/importers/ClientImporters";

import {
  FieldConfigurationCreate,
  FieldConfigurationEdit,
  FieldConfigurationList,
  FieldConfigurationShow,
} from "./components/fieldConfigurations/FieldConfigurations";

import {
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
} from "./components/users/Users";

import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "./components/categories/Categories";

import {
  SubCategoryCreate,
  SubCategoryEdit,
  SubCategoryList,
  SubCategoryShow,
} from "./components/subcategories/Subcategories";

import {
  AsinCreate,
  AsinEdit,
  AsinList,
  AsinShow,
} from "./components/asins/Asins";

import { HelpView } from "./components/help/HelpView";
import { BulkUploadView } from "./components/categories/BulkUploadView";

let entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  entrypoint = window._env_.API_URL;
}

export default () => (
  <HydraAdmin
    dataProvider={dataProvider}
    authProvider={authProvider}
    entrypoint={entrypoint}
    layout={CustomLayout}
    theme={theme}
    loginPage={CustomLogin}
    customRoutes={[
      <Route
        key="resetPassword"
        path="/reset_password"
        component={ResetPassword}
        noLayout
      />,
      <Route key="help" path="/help" component={HelpView} />,
      <Route key="bulkUpload" path="/bulk_upload" component={BulkUploadView} />,
    ]}
  >
    <ResourceGuesser
      name="clients"
      list={ClientList}
      create={ClientCreate}
      edit={ClientEdit}
      show={ClientShow}
    />

    <ResourceGuesser
      name="users"
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
      show={UserShow}
    />

    <ResourceGuesser
      name="client_importers"
      options={{ label: "Client Importers" }}
      list={ClientImporterList}
      create={ClientImporterCreate}
      edit={ClientImporterEdit}
      show={ClientImporterShow}
    />

    <ResourceGuesser
      name="importers"
      list={ImporterList}
      create={ImporterCreate}
      edit={ImporterEdit}
      show={ImporterShow}
    />

    <ResourceGuesser
      name="field_configurations"
      options={{ label: "Field Configurations" }}
      list={FieldConfigurationList}
      create={FieldConfigurationCreate}
      edit={FieldConfigurationEdit}
      show={FieldConfigurationShow}
    />

    <ResourceGuesser
      name="data_points"
      options={{ label: "Data Points" }}
      list={DataPointList}
      create={DataPointCreate}
      edit={DataPointEdit}
      show={DataPointShow}
    />

    <ResourceGuesser
      name="categories"
      list={CategoryList}
      create={CategoryCreate}
      edit={CategoryEdit}
      show={CategoryShow}
    />

    <ResourceGuesser
      name="subcategories"

      options={{ label: "Subcategories" }}
      list={SubCategoryList}
      create={SubCategoryCreate}
      edit={SubCategoryEdit}
      show={SubCategoryShow}
    />

    <ResourceGuesser
      name="asins"
      options={{ label: "ASINs" }}
      list={AsinList}
      create={AsinCreate}
      edit={AsinEdit}
      show={AsinShow}
    />
  </HydraAdmin>
);
